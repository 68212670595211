import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    apiRoot: deriveApiRoot(document.location)
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


function deriveApiRoot(location) {
  var devHost = /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/;
  if (location.hostname === "localhost" || devHost.test(location.hostname)) {
    return location.protocol + "//" + location.hostname + ":7000/";
  } else {
    return location.origin + "/";
  }
}
